import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { ChatService } from '../../services/chat.service';
import { LoginService } from '../../services/login.service';
import { Chat } from 'src/app/structures/chat';

@Component({
    selector: 'app-shared-chat',
    templateUrl: './shared-chat.component.html',
    styleUrls: ['./shared-chat.component.scss'],
    standalone: false
})
export class SharedChatComponent implements OnInit, OnDestroy {
    isLoaded = false;
    sharedChatsLoaded = false;
    sharedChats: Array<any>;
    adminChatsLoaded = false;
    adminChatsIdsList: Array<number>;
    chats: Array<any>;
    headerText: string;
    isGroup: boolean;

    openErrorDialog: Subject<string> = new Subject();

    private subscriptions = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<SharedChatComponent>,
        private chatService: ChatService,
        private loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        const params = {
            type: this.data.type,
            instanceid: this.data.id
        };

        this.subscriptions.add(
            this.chatService.getSharedChats(params).subscribe((data: any) => {
                this.sharedChats = data;
                this.sharedChatsLoaded = true;
                this.sharedChatsReconciliation();
            })
        );

        this.subscriptions.add(
            this.chatService.getChatsSubject().subscribe((data: any) => {
                if (data) {
                    this.adminChatsIdsList = [...data.map((forum) => forum.id)];
                    this.adminChatsLoaded = true;
                    this.sharedChatsReconciliation();
                }
            })
        );

        if (this.data.type === 'group') {
            this.headerText = `Discussions partagées avec ce groupe :`;
        } else if (this.data.type === 'user') {
            this.headerText = `Discussions partagées avec cet utilisateur :`;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    openCreateChat() {
        const queryParams = [];

        queryParams['new'] = 'true';
        queryParams[this.data.type] = this.data.id;

        this.router.navigate(['.'], { queryParams: queryParams });

        this.dialogRef.close();
    }

    openSharedChat(chat: Chat): void {
        this.dialogRef.close(chat);
    }

    sharedChatsReconciliation() {
        if (this.adminChatsLoaded && this.sharedChatsLoaded) {
            this.chats = this.sharedChats.filter((chat) =>
                this.adminChatsIdsList.find((id) => id === chat.id)
            );
            if (this.chats.length === 0) {
                this.openCreateChat();
            }
            this.isLoaded = true;
        }
    }

    /**
     * Ferme la fenêtre actuelle
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
