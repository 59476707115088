import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-preview-image',
    templateUrl: './preview-image.component.html',
    styleUrls: ['./preview-image.component.scss'],
    standalone: false
})
export class PreviewImageComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private domSanitizer: DomSanitizer) {}

    url: SafeResourceUrl;

    ngOnInit(): void {
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    }
}
