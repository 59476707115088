import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';

import { Structure } from '../../../structures/structure';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

/**
 * Composant gérant l'affichage des utilisateurs/groupes dans la fenêtre de création de chat
 */
@Component({
    selector: 'app-create-chat-entry',
    templateUrl: './create-chat-entry.component.html',
    styleUrls: ['./create-chat-entry.component.scss'],
    animations: [slideToggle],
    standalone: false
})
export class CreateChatEntryComponent implements OnInit, OnDestroy {
    @Input() element: any;
    @Input() public: boolean;

    @Input() role: string;
    @Output() roleChange: EventEmitter<any> = new EventEmitter();

    @Input() selected: boolean;
    @Output() selectedChange: EventEmitter<any> = new EventEmitter();

    openedGroup: boolean;
    type: string;

    structures: Array<Structure>;
    structureOpened: boolean;

    getGroup$: Subscription;

    constructor(private usersService: UsersService, private dialogService: DialogService) {}

    ngOnInit() {
        this.openedGroup = false;

        if (this.element.shortname && this.element.name) {
            this.type = 'role';
        } else if (this.element.name && !this.element.shortname) {
            this.type = 'group';
        } else {
            this.type = 'user';
        }

        if (this.role === undefined) {
            this.role = 'writer';
            this.roleChange.emit(this.role);
        }
    }

    ngOnDestroy() {
        if (this.getGroup$) {
            this.getGroup$.unsubscribe();
        }
    }

    /**
     * Indique l'icône à afficher selon le type et le rôle de l'element actuel
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getIconStyle(): string {
        if (this.element.roles === undefined) {
            return 'icon-groupe';
        } else {
            return this.getUserIcon(this.element);
        }
    }

    getUserIcon(user) {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isReader(): boolean {
        return this.role === 'reader';
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isWriter(): boolean {
        return this.role === 'writer';
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isModerator(): boolean {
        return this.role === 'moderator';
    }

    /**
     * Modifie le rôle de l'utilisateur dans le chat
     * @param {boolean} boolean Un booléen indiquant si l'utilisateur doit avoir les rôles de modérateur
     */
    toggleRole() {
        if (this.role === 'reader') {
            this.role = 'writer';
        } else if (this.role === 'writer') {
            this.role = 'moderator';
        } else if (this.role === 'moderator') {
            this.role = 'reader';
        }
        this.roleChange.emit(this.role);
    }

    /**
     * Renvoie le nom à afficher pour l'élement actuel
     * @returns {string} Renvoie le nom du group si l'élement est un groupe, ou le prénom et le nom si l'élement est un utilisateur
     */
    getElementName(): string {
        if (this.element.name) {
            return this.element.name;
        } else {
            return this.element.lastname.toUpperCase() + ' ' + this.element.firstname;
        }
    }

    /**
     * Sélectionne/déselectionne l'element actuel
     */
    toggleSelected() {
        this.selected = !this.selected;
        this.selectedChange.emit(this.selected);
    }

    /**
     * Indique si l'élement actuel est sélectionné ou non
     */
    isElementSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le groupe est déplié
     */
    isGroupOpened() {
        return this.openedGroup;
    }

    /**
     * @param {event} $event L'évenement JS émi lors du clic
     * Déplie le groupe sélectionné
     */
    showGroup(): void {
        if (this.element.users === undefined) {
            this.getGroup$ = this.usersService.getGroup(this.element.id).subscribe(
                (data: any) => {
                    this.element = data;
                    this.openedGroup = !this.openedGroup;
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
        } else {
            this.openedGroup = !this.openedGroup;
        }
    }
}
