import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UsersService } from '../../../../services/users.service';
import { LoginService } from '../../../../services/login.service';
import { DialogService } from '../../../../services/dialog.service';
import { PermissionService } from '../../../../services/permission.service';
import { ConfigService } from '../../../../services/config.service';

import { Chat } from 'src/app/structures/chat';
import { Attachment } from 'src/app/structures/attachment';

@Component({
    selector: 'app-chat-entry',
    templateUrl: './chat-entry.component.html',
    styleUrls: ['./chat-entry.component.scss'],
    standalone: false
})
export class ChatEntryComponent implements OnInit, OnDestroy {
    @Input() message: any;
    @Input() last: boolean;
    @Input() chat: Chat;
    @Input() quote: boolean;
    @Input() cancelable: boolean;

    @Output() clickEditMessage: EventEmitter<any> = new EventEmitter();
    @Output() clickQuoteMessage: EventEmitter<any> = new EventEmitter();
    @Output() scrollToQuoteMessage: EventEmitter<number> = new EventEmitter();

    userReady$: Subscription;

    author: string;
    authorRole: string;
    timestamp: string;
    content: string[] = [];
    links: string[] = [];
    position = 'undefined';
    disabled: boolean;
    initials = '';
    nbRead: number;
    nbNotRead: number;
    discussionId: number;
    isBeingEdited: boolean;

    constructor(
        private userService: UsersService,
        private loginService: LoginService,
        private dialogService: DialogService,
        private permissionService: PermissionService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        if (this.loginService.getUser()) {
            this.initMessage();
        }
        this.userReady$ = this.loginService.userReady.subscribe(() => {
            this.initMessage();
        });
    }

    ngOnDestroy() {
        this.userReady$.unsubscribe();
    }

    initMessage() {
        if (!this.message.deleted) {
            this.isBeingEdited = false;
            this.author =
                this.message.author.lastname.toUpperCase() + ' ' + this.message.author.firstname;
            // this.initials =
            //     this.message.author.lastname.toUpperCase().charAt(0) + this.message.author.firstname.charAt(0);
            this.timestamp = new Date(this.message.timecreated).toLocaleString('fr-FR');
            this.nbRead = this.message.read;
            this.nbNotRead = this.message.notread;
            this.parseHttpsLink(this.message.message);
            this.authorRole = this.userService.getUserRole(this.message.author.roles);
            this.discussionId = this.message.discussionid;
            if (this.authorRole) {
                this.authorRole = this.authorRole.toLowerCase();
            }
        } else {
            if (this.message.author) {
                this.author =
                    this.message.author.lastname.toUpperCase() +
                    ' ' +
                    this.message.author.firstname;
                this.initials =
                    this.message.author.lastname.toUpperCase().charAt(0) +
                    this.message.author.firstname.charAt(0);
            }
            this.timestamp = new Date(this.message.timecreated).toLocaleString('fr-FR');
        }

        if (this.quote) {
            this.position = 'position-left';
        } else if (this.message.author) {
            this.position =
                this.loginService.getUser().id === this.message.author.id
                    ? 'position-right'
                    : 'position-left';
        } else {
            this.position = 'position-right';
        }
    }

    private parseHttpsLink(message: string) {
        let i: number;

        while ((i = message.indexOf('https://')) > -1) {
            this.content.push(message.substring(0, i));
            message = message.slice(i);
            if ((i = message.search(/\s/)) > -1) {
                this.links.push(message.substring(0, i));
                message = message.slice(i);
            } else {
                this.links.push(message);
                return;
            }
        }
        if (message) {
            this.content.push(message);
        }
    }

    isCurrentOwner(): boolean {
        if (this.message.author) {
            return this.loginService.getUser()
                ? this.loginService.getUser().id === this.message.author.id
                : false;
        } else {
            return false;
        }
    }

    isModerator() {
        return this.loginService.isChatModerator(this.chat);
    }

    isRead(): boolean {
        return this.message.readOn !== null;
    }

    openDeleteMessageDialog() {
        this.dialogService.openDeleteMessageDialog(this.message);
    }

    canDeleteMessage() {
        return this.permissionService.canDeleteMessage(this.chat, this.message) && !this.quote;
    }

    canEditMessage() {
        return this.permissionService.canEditMessage(this.chat, this.message) && !this.quote;
    }

    canQuoteMessage() {
        return !this.quote;
    }

    cancelQuote() {
        this.clickQuoteMessage.emit({ message: undefined });
    }

    goToQuotedMessage() {
        this.scrollToQuoteMessage.emit(this.message.quote.id);
    }

    editMessage() {
        this.isBeingEdited = true;
        this.clickEditMessage.emit(this.message);
    }

    cancelEdition() {
        this.message.isEditing = false;
        this.isBeingEdited = false;
    }

    quoteMessage() {
        this.clickQuoteMessage.emit({ message: this.message });
    }

    getFormattedSize(size: number): String {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }

    isImage(attachment: Attachment): boolean {
        if (attachment && attachment.extension) {
            return (
                ['jpg', 'jpeg', 'gif', 'png', 'apng', 'svg', 'bmp'].indexOf(
                    attachment.extension.toLowerCase()
                ) > -1
            );
        }
    }

    getAttachmentUrl(attachment: Attachment): string {
        return (
            this.configService.getEndPoint() +
            '/messages/' +
            this.message.id +
            '/attachments/' +
            attachment.name
        );
    }

    downloadAttachment(attachmentName: string) {
        return (
            this.configService.getEndPoint() +
            '/messages/' +
            this.message.id +
            '/attachments/' +
            encodeURIComponent(attachmentName)
        );
    }

    openPreview(attachment: Attachment) {
        this.dialogService.openPreviewImage(this.getAttachmentUrl(attachment));
    }
}
