import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../services/dialog.service';
import { ChatService } from '../../../services/chat.service';
import { MessageService } from '../../../services/message.service';
import { LoginService } from '../../../services/login.service';
import { PermissionService } from '../../../services/permission.service';

import { Chat } from 'src/app/structures/chat';
import { Message } from 'src/app/structures/message';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    standalone: false
})
export class ChatComponent implements OnInit, AfterViewChecked {
    LIMIT = 30;

    chat: Chat;
    canEditChat: boolean;
    messages: any[];
    chatMembers = '';
    scroll = false;
    search = '';
    quote: Message;

    constructor(
        private messageService: MessageService,
        private dialogService: DialogService,
        private chatService: ChatService,
        private loginService: LoginService,
        private permissionService: PermissionService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.loginService.userReady.subscribe(() => {
            this.refreshChat();
        });
        this.chatService.refresh.subscribe(() => {
            this.refreshChat();
        });
        this.route.paramMap.subscribe(() => {
            this.refreshChat();
        });
        this.messageService.refresh.subscribe(() => {
            this.refreshMessages();
        });
        this.messageService.scrollToBottom.subscribe(() => {
            this.scroll = true;
        });

        this.canEditChat = true;
    }

    refreshChat() {
        const chatId = +this.route.snapshot.paramMap.get('id');

        this.chatService.getChat(chatId).subscribe((data: any) => {
            this.chat = data;

            this.canEditChat = false;
            if (this.chat) {
                for (const i in this.chat.users) {
                    if (!this.chat.users[i].roles.learner && !this.chat.users[i].roles.prospect) {
                        this.canEditChat = true;
                    }
                }
            }
            this.refreshMessages();
            this.initChatMembers();
            this.chatService.emitSetAsActive(this.chat);
        });
    }

    ngAfterViewChecked() {
        if (this.scroll) {
            this.scrollToBottom();
        }
    }

    initChatMembers() {
        if (this.loginService.getUser()) {
            this.chatMembers = '';
            for (const i in this.chat.users) {
                if (this.chat.users[i].id !== this.loginService.getUser().id) {
                    this.chatMembers +=
                        this.chat.users[i].lastname.toUpperCase() +
                        ' ' +
                        this.chat.users[i].firstname +
                        ', ';
                }
            }
            for (const i in this.chat.groups) {
                if (this.chat.groups[i].name) {
                    this.chatMembers += this.chat.groups[i].name + ', ';
                }
            }

            this.chatMembers = this.chatMembers.substring(0, this.chatMembers.length - 2);
        }
    }

    getChatMembers() {
        return this.chatMembers;
    }

    getChatName() {
        return this.chat.name !== 'empty' ? this.chat.name : '';
    }

    getTitle() {
        if (!this.chat) {
            return '';
        }
        return this.chat.name !== 'empty' ? this.chat.name : this.getChatMembers();
    }

    isModerator() {
        return this.loginService.isChatModerator(this.chat);
    }

    refreshMessages() {
        this.messageService.getMessages(this.LIMIT, this.chat.id, this.search).subscribe(
            (list: any[]) => {
                this.messages = list.reverse();
                this.scroll = true;
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
                this.scroll = true;
            }
        );
    }

    scrollToMessage(quoteid) {
        const messageToScroll = document.querySelector(
            `[data-message-id="${quoteid}"]:not(.quote)`
        );
        if (messageToScroll) {
            messageToScroll.scrollIntoView({ behavior: 'smooth' });
        } else {
            this.getPreviousMessages(quoteid);
        }
    }

    scrollToBottom() {
        const container = document.getElementsByClassName('main-container')[0];
        if (container.scrollHeight > 0) {
            container.scrollTop = container.scrollHeight - container.clientHeight;
            this.scroll = false;
        }
    }

    getPreviousMessages(scrollToMessage?) {
        this.messageService
            .getMessages(this.LIMIT, this.chat.id, this.search, this.messages[0].id)
            .subscribe(
                (list: any[]) => {
                    this.messages = list.reverse().concat(this.messages);
                    document.getElementsByClassName('message-container')[0].scrollTop = 1;
                    if (scrollToMessage) {
                        this.scrollToMessage(scrollToMessage);
                    }
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
    }

    editMessage($event) {
        this.messageService.emitEditMessage($event);
    }

    quoteMessage($event) {
        console.log($event.message);
        this.quote = $event.message;
    }
}
