import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from '../../../../services/dialog.service';
import { MessageService } from '../../../../services/message.service';
import { LoginService } from '../../../../services/login.service';
import { PermissionService } from '../../../../services/permission.service';

import { Chat } from 'src/app/structures/chat';
import { Message } from 'src/app/structures/message';

@Component({
    selector: 'app-chat-footer',
    templateUrl: './chat-footer.component.html',
    styleUrls: ['./chat-footer.component.scss'],
    standalone: false
})
export class ChatFooterComponent implements OnInit {
    @Input() chat: Chat;
    @Input() messages: Array<Message>;
    @Input() quote: Message;

    canEditChat: boolean;
    message: Message;
    messageValue: string = '';

    files: Array<any>;

    @ViewChild('messageInput') messageInput: ElementRef;

    LIMIT = 30;

    constructor(
        private messageService: MessageService,
        private dialogService: DialogService,
        private loginService: LoginService,
        private permissionService: PermissionService
    ) {}

    ngOnInit() {
        this.files = [];
        this.message = {
            isEditing: false,
            message: ''
        };

        this.messageService.startEditMessage.subscribe((event) => {
            this.message = event;
            this.editMessage();
        });
    }

    cancelQuote() {
        this.quote = undefined;
    }

    editLastMessage() {
        if (!this.message.isEditing) {
            this.message = this.messages[this.messages.length - 1];
            this.messages.map((message) => (message.isEditing = false));
            this.message.isEditing = true;

            this.editMessage();
        }
    }

    editMessage() {
        this.messages.map((message) => (message.isEditing = false));
        this.message.isEditing = true;
        this.messageInput.nativeElement.focus();
        this.messageInput.nativeElement.innerHTML = this.message.message;
    }

    cancelMessageEdition() {
        this.message.isEditing = false;
        document.getElementById('message-input').innerHTML = '';
    }

    canPostMessage() {
        if (this.chat) {
            return this.permissionService.canPostMessage(this.chat);
        }
    }

    parseMessage(value: string) {
        if (value.replace(/<br>/g, '') === '') {
            return '';
        } else {
            return value;
        }
    }

    sendMessage(event: any) {
        event.preventDefault();

        let message: Message = {};
        let action: any;

        if (this.message.isEditing) {
            message = {
                ...this.message,
                message: this.parseMessage(this.messageInput.nativeElement.innerHTML.slice(0, 1023))
            };
            action = this.messageService.editMessage(message);
        } else {
            message = {
                message: this.parseMessage(
                    this.messageInput.nativeElement.innerHTML.slice(0, 1023)
                ),
                author: {
                    id: this.loginService.getUser().id,
                    firstname: this.loginService.getUser().firstname,
                    lastname: this.loginService.getUser().lastname.toUpperCase()
                },
                deleted: false,
                discussionid: this.chat.id,
                timecreated: new Date().toISOString(),
                attachments: [],
                temporary: true
            };

            this.messages.push(message);
            this.messageService.emitScrollToBottom();
            action = this.messageService.createMessage(
                message.message,
                message.discussionid,
                this.quote?.id
            );
        }

        this.messageInput.nativeElement.innerHTML = '';
        action.subscribe(
            (data) => {
                if (this.files.length > 0) {
                    for (const file of this.files) {
                        file.extension = file.name.split('.')[file.name.split('.').length - 1];
                        this.messageService.createAttachment(data.id, file).subscribe(
                            () => {
                                if (file === this.files[this.files.length - 1]) {
                                    this.files = [];
                                    this.messageService.refreshPosts();
                                    this.messageService.updateMessage({
                                        id: this.chat.id,
                                        message: message.message
                                    });
                                    this.message.message = '';
                                    this;
                                    this.message.isEditing = false;
                                    this.quote = undefined;
                                }
                            },
                            (error: HttpErrorResponse) => {
                                this.dialogService.openErrorDialog(error.error.userMessage);
                            }
                        );
                    }
                } else {
                    this.messageService.refreshPosts();
                    this.messageService.updateMessage({
                        id: this.chat.id,
                        message: message.message
                    });
                    this.message.isEditing = false;
                    this.message.message = '';
                    this.quote = undefined;
                }
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
            }
        );
    }

    openUploadFilesDialog() {
        this.dialogService.openUploadFilesDialog().subscribe((data: Array<File>) => {
            if (data !== undefined) {
                this.files = this.files.concat(data);
            }
        });
    }

    removeAttachment(index: number) {
        if (this.files.length) {
            this.files.splice(index, 1);
        }
    }

    getFormattedSize(size: number): string {
        if (size > 1000000) {
            return Math.floor(size / 1000000) + 'Mo';
        } else if (size > 1000) {
            return Math.floor(size / 1000) + 'Ko';
        } else {
            return Math.floor(size) + 'o';
        }
    }

    pasteHTML(e: any) {
        e.preventDefault();

        // Get the copied text from the clipboard
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');

        // Insert text at the current position of caret
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();

        const textNode = document.createTextNode(text);
        range.insertNode(textNode);
        range.selectNodeContents(textNode);
        range.collapse(false);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
