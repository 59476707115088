import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { ChatService } from '../../services/chat.service';
import { MessageService } from '../../services/message.service';
import { Subject, Subscription } from 'rxjs';

import { Chat } from '../../structures/chat';

/**
 * Composant gérant l'affichage de la fenêtre d'erreur
 */
@Component({
    selector: 'app-delete-chat',
    templateUrl: './delete-chat.component.html',
    styleUrls: ['./delete-chat.component.scss'],
    standalone: false
})
export class DeleteChatComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<DeleteChatComponent>,
        private chatService: ChatService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public chat: any
    ) {}
    openErrorDialog: Subject<string> = new Subject();

    message: string;
    deletionDone: boolean;

    deleteChat$: Subscription;
    deleteMessage$: Subscription;

    ngOnInit() {
        this.deletionDone = false;
        if (this.chat.name === 'empty') {
            this.chat.name = '';
        }
    }

    ngOnDestroy() {
        if (this.deleteChat$) {
            this.deleteChat$.unsubscribe();
        }
        if (this.deleteMessage$) {
            this.deleteMessage$.unsubscribe();
        }
    }

    deleteChat(chat: Chat) {
        this.deleteChat$ = this.chatService.deleteChat(chat.id).subscribe(
            (data: any) => {
                this.deletionDone = true;
                this.chatService.refreshChats();
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }

    deleteMessage(message) {
        this.deleteMessage$ = this.messageService.deleteMessage(message.id).subscribe(
            (data: any) => {
                this.deletionDone = true;
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.next(error.error.userMessage);
            }
        );
    }
    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
